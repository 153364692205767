import axios from "axios";

import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";
import { CONSTANTS } from "src/constants";
import { ENDPOINTS } from "src/store/endpoints";
import { showToast } from "src/utils";
import dayjs from "dayjs";
import { SORT_BY, USER_STATUS } from "src/types";
import moment from "moment";
import { CONTAINER_TYPES } from "src/pages/customer/quotations-2/elements/fcl-quotation/container-type/container-type.component";
import { v4 as uuidv4 } from "uuid";

export const formatDateQuotation = (dateString: string) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Convert to Netherlands time
  const netherlandsDate = new Date(dateString);
  netherlandsDate.toLocaleString("en-NL", { timeZone: "Europe/Amsterdam" });

  // Extract components for formatting
  const month = months[netherlandsDate.getMonth()];
  const day = netherlandsDate.getDate();
  const year = netherlandsDate.getFullYear();
  const hours = netherlandsDate.getHours() + 1;
  const minutes = netherlandsDate.getMinutes();
  const seconds = netherlandsDate.getSeconds();
  const period = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  const formattedHours = hours % 12 || 12;

  // Add leading zeros to minutes and seconds if they are single digits
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
  const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;

  return dateString
    ? `${month} ${day}, ${year}, ${formattedHours}:${formattedMinutes}:${formattedSeconds} ${period}`
    : "";
};

export const loginUserApi = () => {
  return new Promise<any>((resolve, reject) => {
    setTimeout(() => {
      resolve({
        name: "Test",
        email: "test@gmail.com",
      });
    }, 1000);
  });
};

export const checkForEmptyBioUser = (user: any) => {
  if (
    !user?.first_name ||
    !user?.last_name ||
    !user?.dob ||
    !user?.profession ||
    !user?.country
  ) {
    showToast(
      TOASTR_TYPES.ERROR,
      "Please fill the required fields in personal info",
    );
    return true;
  } else if (user?.biography?.length == 0) {
    showToast(
      TOASTR_TYPES.ERROR,
      "You cannot publish the biography as it is currently empty",
    );
    return true;
  } else {
    return false;
  }
};

let timerId: NodeJS.Timeout;

let cancelTokenSource = axios.CancelToken.source();

export const checkEmailExists = (data: any) => {
  clearTimeout(timerId);
  // Cancel the previous request
  cancelTokenSource.cancel();

  // Create a new CancelToken source for the current request
  cancelTokenSource = axios.CancelToken.source();

  return new Promise((resolve, reject) => {
    timerId = setTimeout(() => {
      axios
        .post(
          process.env.REACT_APP_API_URL + ENDPOINTS.ADMIN.CHECK_EMAIL_EXISTS,
          data,
          {
            cancelToken: cancelTokenSource.token,
          },
        )
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err?.response?.data);
        });
    }, 500);
  });
};

export const formatDataForUpdatePricings = (obj: any) => {
  const data: any = [];

  const mapping: any = {
    arr: "pre-carriage",
    arr1: "origin",
    arr2: "destination",
    arr3: "ocean-freight",
  };

  for (const key in obj) {
    obj[key]?.forEach((item: any) => {
      data.push({
        charge_type: mapping[key],
        sub_charge_type: item.type,
        charges:
          (item.per_rate_basis == "0.00"
            ? item?.charges
            : item.per_rate_basis) * item?.qty,
        rate_basis: item.rate_basis,
        qty: item.qty,
        per_rate_basis:
          item.per_rate_basis == "0.00" ? item?.charges : item.per_rate_basis,
        direction:
          mapping[key] == "origin"
            ? "export"
            : mapping[key] == "destination"
              ? "import"
              : "",
      });
    });
  }

  return data;
};

export const getFilterCountQuotation = (data: any, filterType: any) => {
  let count = 0;
  Array.isArray(data) &&
    data?.forEach((quotation: any) => {
      if (quotation.mode_of_transport === filterType) {
        count++;
      }
    });
  return count;
};

export const convertKgCM = (value: number, unit: any) => {
  let ratio = 2.54;

  if (!unit) {
    ratio = 1 / 2.54;
  }

  const convertedVal = (value * ratio * 10) / 10;

  return naiveRound(convertedVal, 2);
};

export const convertVolumeUnit = (value: number, unit: any) => {
  let ratio = 35.314667;

  if (!unit) {
    ratio = 1 / 35.314667;
  }

  const convertedVal = (value * (1 / ratio) * 10) / 10;

  return naiveRound(convertedVal, 2);
};

export const convertPoundKg = (value: number, unit: any) => {
  if (!unit) {
    //kg to lb
    return naiveRound(value * 2.205);
  }

  return naiveRound((value * 1) / 2.205);
};

export function naiveRound(num: number, decimalPlaces = 2) {
  const p = Math.pow(10, decimalPlaces);
  return Math.round(num * p) / p;
}

export const convertVolume = (value: any) => {
  const calResult = Number.parseFloat(value) / 1000000;

  return calResult.toFixed(3) || "-";
};

export const generateErrorMessage = (
  field: string,
  isValueLimit = false,
  value?: string,
) => {
  if (isValueLimit) {
    return `Max ${field} ${value}`;
  }
  return "Please enter " + field;
};

export const convertDataForPricings = (input: any) => {
  const mapping: { [key: string]: string } = {
    preCarriage: "pre-carriage",
    origin: "origin",
    oceanFreight: "ocean-freight",
    destination: "destination",
  };

  const newObj: { [key: string]: any } = {};
  for (const x in input) {
    newObj[mapping[x]] = input[x].map((e: any) => ({ [e.carriage]: e.amount }));
  }
  return newObj;
};

export const isCustomQuotation = (formikQuotation: any) => {
  return formikQuotation?.values?.quotation_type !== "internal";
};

export const isCustomQuotationUpdated = (formikQuotation: any) => {
  return !["external", "internal"].includes(
    formikQuotation?.values?.quotation_type,
  );
};

export const checkErrorCargoItem = (
  errors: any,
  total_cargo_dimensions: boolean,
) => {
  return total_cargo_dimensions
    ? errors?.volume || errors?.weight
    : errors?.height || errors?.length || errors?.width || errors?.weight;
};

export const formatQuotationReference = (data: any) => {
  if (data?.reference_no && data?.uid) {
    return data?.reference_no + "-" + data?.uid;
  } else if (data?.uid) {
    return data?.uid || "";
  } else {
    return data?.reference_no || "-";
  }
};

export const formatDataForCustomerQuotationExport = (data: any) => {
  return data.map((item: any) => {
    return {
      "Reference No.": formatQuotationReference(item) || "",
      "Creation Date": formatDateQuotation(item?.created_at),
      Origin: ` ${item?.source_port} ,${item?.source_country}`,
      Destination: `${item?.destination_port} ,${item?.destination_country} `,
      MOT: item?.mode_of_transport?.toUpperCase(),
      "Service Type": item?.quotation_type?.toUpperCase(),
      Status: item?.status?.toUpperCase(),
    };
  });
};

export const checkDefaultCustomer = (user: any) => {
  return user?.email === CONSTANTS.DEFAULT_CUSTOMER_EMAIL;
};

export const formatWebsiteUrl = (url: any) => {
  // Check if the URL starts with "https://" or "http://"
  // Trim the URL and check if it is empty or whitespace-only
  const trimmedUrl = url?.trim();
  if (!trimmedUrl) {
    // If empty or whitespace-only, return an empty string
    return "";
  }

  if (!trimmedUrl.startsWith("https://") && !url.startsWith("http://")) {
    // If not, prepend "https://"
    return `https://${url}`;
  }

  // If the URL already starts with "https://" or "http://", return as is
  return url;
};

export const formatDateQuotationRoute = (dateString: string) => {
  // Convert to Netherlands time
  const netherlandsDate = new Date(dateString);
  netherlandsDate.toLocaleString("en-NL", { timeZone: "Europe/Amsterdam" });

  // Extract components for formatting
  const month = netherlandsDate.getMonth() + 1;
  const day = netherlandsDate.getDate();
  const year = netherlandsDate.getFullYear();

  return dateString ? `${day}/${month}/${year}` : "";
};

export const roundToTwoDecimalPoints = (input: string | number) => {
  const inputStr = typeof input === "number" ? String(input) : input;
  const numberStr = inputStr.replace(/[^\d.]/g, "");

  const number = parseFloat(numberStr);

  if (isNaN(number)) {
    return NaN;
  } else if (Number.isInteger(number)) {
    return number;
  } else {
    return Math.round((number + Number.EPSILON) * 100) / 100;
  }
};

export const getCurrentDate = () => {
  return dayjs(new Date());
};

export const convertToLowerCaseWithSpaces = (value: string) => {
  return value?.replace(/_/g, " ").toLowerCase() || value;
};

export const removeCommas = (inputString: string): any => {
  return inputString?.replace(/,/g, "") || "";
};

export const roundToTwoDecimalPoint = (input: string | number) => {
  // Convert input to a string if it's not already a string
  const inputStr = typeof input === "number" ? String(input) : input;

  // Extract the numerical part, keeping the leading negative sign (if present)
  const numberStr = inputStr.replace(/[^-\d.]/g, "").replace(/(?!^)-/g, "");

  // Convert the extracted numerical part to a number
  const number = parseFloat(numberStr);

  // Check if the extracted number is valid
  if (isNaN(number)) {
    return NaN; // Return NaN if the extracted number is not valid
  } else if (Number.isInteger(number)) {
    return number; // Return the number unchanged if it's an integer
  } else {
    return Math.round((number + Number.EPSILON) * 100) / 100; // Round to two decimal points otherwise
  }
};

export const objectToQueryParams = (obj: Record<string, any>) => {
  const queryParams = Object.keys(obj)
    .filter(
      (key) => obj[key] !== undefined && obj[key] !== null && obj[key] !== "",
    )
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join("&");
  return queryParams;
};

export const formatSortingPayload = (payload: any) => {
  return Object.keys(payload)?.length > 0 ? JSON.stringify(payload) : null;
};

// Define label and class name for each status
const userStatusInfo: {
  [key in USER_STATUS]: { label: string; className: string };
} = {
  [USER_STATUS.APPROVED]: {
    label: "Approved",
    className: "approved",
  },
  [USER_STATUS.PENDING_APPROVAL]: {
    label: "Pending Approval",
    className: "pending",
  },
  [USER_STATUS.REJECTED]: {
    label: "Rejected",
    className: "rejected",
  },
};
// Function to get label from backend status number
export const getUserStatusLabel = (status: USER_STATUS) => {
  return userStatusInfo[status];
};

export const scrollToBottom = () => {
  window.scrollTo({
    top: window.scrollY + 150,
    behavior: "smooth",
  });
};

export const getTermNameFromValue = (value: string) => {
  return (
    CONSTANTS.FCL.TERMS_OPTIONS.find((opt: any) => opt?.value == value)
      ?.label || "-"
  );
};

export const formatContainerTypeAndSizeForPreview = (items: any[]) => {
  if (!Array.isArray(items)) return "";

  // Filter only items with all three values
  const validItems = items.filter(
    (item) => item.container_type && item.quantity && item.weight,
  );

  // Map to formatted string and join with commas
  const formatted = validItems
    .map((item) => `${item.quantity}x ${item.container_type}`)
    .join(", ");

  return formatted || "-";
};

export const getSelectedContainerTypes = (items: any, idx: number) => {
  return items
    ?.filter((item: any, index: number) =>
      items?.length == 0
        ? item.container_type !== ""
        : item.container_type !== "" && index !== idx,
    )
    ?.map((item: any) => item.container_type);
};

export const removeSelectedContainerTypeFromOptions = (
  containerTypes: any,
  selectedValues: any,
  idx: number,
) => {
  return containerTypes
    ?.map((containerType: any) => ({
      ...containerType,
      options: containerType.options.filter(
        (item: any) => !selectedValues.includes(item?.value),
      ),
    }))
    .filter((containerType: any) => containerType.options.length > 0);
};

export const getContainerTypesCount = () => {
  let count = 0;
  for (const containerType of CONTAINER_TYPES) {
    count += containerType.options.length;
  }
  return count;
};

export const sanitizeNumberInput = (input: string): string => {
  // Replace any non-numeric characters except the decimal point
  let sanitized = input.replace(/[^0-9.]/g, "");

  // Allow only one decimal point
  if ((sanitized.match(/\./g) || []).length > 1) {
    sanitized = sanitized.replace(/\.(?=.*\.)/, "");
  }

  // Remove leading zero if present
  if (
    sanitized.startsWith("0") &&
    sanitized.length > 1 &&
    sanitized[1] !== "."
  ) {
    sanitized = sanitized.slice(1);
  }

  return sanitized;
};

export const checkValidCharges = (charges: any): boolean => {
  return (
    charges?.charges?.every((charge: any) =>
      charge.details.every((detail: any) => {
        const hasValidName = detail.name.trim().length > 0; // Name must not be empty
        const hasValidValues = detail.values.every(
          (value: any) =>
            value !== null &&
            value !== undefined &&
            value !== "" &&
            !isNaN(value),
        ); // Values must not be empty or invalid

        return hasValidName && hasValidValues;
      }),
    ) && charges?.charges?.some((charge: any) => charge.details?.length > 0)
  );
};
export const checkValidOneTimeCharges = (charges: any): boolean => {
  return charges?.oneTimeCharges?.every((charge: any) =>
    charge?.details?.every((detail: any) => {
      console.log(detail?.name, "detail?.name>>>>>");

      const hasValidName = detail?.name?.toString()?.trim().length > 0; // Name must not be empty
      const hasValidValues = detail?.values; // Values must not be empty or invalid

      return hasValidName && hasValidValues;
    }),
  );
};

export const getInitialOneTimeCharges = (oneTimeCharges: any = []) => {
  if (oneTimeCharges?.length == 0) {
    return [
      {
        id: generateUUID(),
        category: "Freight",
        total: 0,
        details: [
          {
            id: generateUUID(),
            name: "",
            values: "",
          },
        ],
      },

      {
        id: generateUUID(),
        category: "Origin",
        total: 0,
        details: [
          {
            id: generateUUID(),
            name: "",
            values: "",
          },
        ],
      },
      {
        id: generateUUID(),
        category: "Destination",
        total: 0,
        details: [
          {
            id: generateUUID(),
            name: "",
            values: "",
          },
        ],
      },
    ];
  } else {
    return oneTimeCharges;
  }
};

export const getInitialContainerCharges = () => {
  return [
    {
      id: generateUUID(),
      category: "Freight",
      total: 0,
      details: [
        {
          id: generateUUID(),
          name: "",
          values: [],
          isEditable: true,
        },
      ],
      isEditable: true,
    },
    {
      id: generateUUID(),
      category: "Origin",
      total: 0,
      details: [
        {
          id: generateUUID(),
          name: "",
          values: [],
          isEditable: true,
        },
      ],
      isEditable: true,
    },
    {
      id: generateUUID(),
      category: "Destination",
      total: 0,
      details: [
        {
          id: generateUUID(),
          name: "",
          values: [],
          isEditable: true,
        },
      ],
      isEditable: true,
    },
  ];
};

export const calculateTotalPerCharge = ({
  colIndex,
  chargeId,
  charges,
}: any) => {
  const charge = charges.find((charge: any) => charge.id === chargeId);

  const total = charge?.details?.reduce((acc: any, val: any) => {
    return acc + val.values[colIndex];
  }, 0);
  return roundToTwoDecimalPoint(total);
};

export const calculateSubTotalPerContainer = ({
  colIndex,
  charges,
  quantity,
}: any) => {
  let total = 0;
  charges.forEach((charge: any) => {
    total += calculateTotalPerCharge({
      colIndex,
      chargeId: charge.id,
      charges,
    });
  });
  // total = total * quantity;
  return formatToTwoDecimalPoints(total);
};

export const calculateFinalTotalPrice = ({
  charges,
  columns,
  oneTimeCharges,
}: any) => {
  let total = 0;
  columns?.forEach((column: any, colIndex: any) => {
    charges?.forEach((charge: any) => {
      let subTotal = 0;
      charge.details.forEach((detail: any) => {
        subTotal += detail.values[colIndex];
      });
      total += subTotal * column.quantity;
    });
  });

  oneTimeCharges?.forEach((charge: any) => {
    charge?.details?.forEach((detail: any) => {
      if (Array.isArray(detail?.values)) {
        total += detail?.values.reduce(
          (acc: number, val: number) => acc + val,
          0,
        );
      } else {
        total += (detail?.values as number) || 0;
      }
    });
  });

  return formatToTwoDecimalPoints(total);
};

export const calculateTotalOneTimeFee = (oneTimeCharges: any) => {
  if (!oneTimeCharges) return 0;

  return formatToTwoDecimalPoints(
    oneTimeCharges.reduce(
      (acc: number, { details }: any) =>
        acc +
        details.reduce(
          (sum: number, { values }: any) =>
            sum + (isNaN(values) || values === "" ? 0 : values),
          0,
        ),
      0,
    ),
  );
};

export const formatToTwoDecimalPoints = (value: number): any => {
  if (typeof value == "number") {
    return value?.toFixed(2);
  } else {
    return 0;
  }
};

export const truncateString = (value: string, maxLength: number): string => {
  if (typeof value === "string" && value.length > maxLength) {
    return value.slice(0, maxLength) + "...";
  }
  return value;
};

export const checkHasValidQuotationItems = (cargoItems: any) => {
  return cargoItems.some((item: any) => item?.container_type);
};

export const isAdmin = (pathname: string) => {
  return pathname.includes("admin");
};
export const formatCargoItemsFcl = (cargoItemSpecs: any) => {
  return cargoItemSpecs.map((item: any) => {
    // Find the matching container type in CONTAINER_TYPES
    for (const containerType of CONTAINER_TYPES) {
      const match = containerType.options.find(
        (option) => option.value === item.container_type,
      );
      if (match) {
        // Add sizeTypeId to the item
        return {
          // ...item,
          quantity: item?.quantity,
          container_type: item?.container_type,
          weight: item?.weight,
          sizeTypeId: match.sizeTypeId,
        };
      }
    }

    // Return the item unchanged if no match is found
    return item;
  });
};
export const formatInputToTwoDecimals = (value: string) => {
  // Remove non-numeric characters except the decimal point
  let inputValue = value.replace(/[^0-9.]/g, "");

  // Prevent leading zeros unless followed by a decimal point
  if (inputValue.startsWith("0") && !inputValue.startsWith("0.")) {
    inputValue = inputValue.replace(/^0+/, "");
  }

  // Ensure only one decimal point
  const parts = inputValue.split(".");
  if (parts.length > 2) {
    inputValue = `${parts[0]}.${parts[1]}`;
  }

  // Enforce max 5 digits before the decimal point
  if (parts[0].length > 5) {
    parts[0] = parts[0].slice(0, 5);
  }

  // Enforce max 2 digits after the decimal point
  if (parts[1]?.length > 2) {
    parts[1] = parts[1].slice(0, 2);
  }

  // Join the parts back together
  inputValue = parts.join(".");

  return inputValue;
};

export const parseCurrency = (value: string) => {
  // if (typeof value !== "string") {
  //   throw new Error("Input must be a string");
  // }
  // Remove commas and convert to a floating-point number
  const numericValue = parseFloat(value?.replace(/,/g, ""));

  // Check if the conversion was successful
  if (isNaN(numericValue)) {
    throw new Error("Invalid numeric value");
  }

  return numericValue;
};

export const makeFclQuotationPayload = (data: any) => {
  const payload = {
    quotation_type: data?.quotation_type,
    date: data?.date,
    service_type: data?.service_type,
    terms: data?.terms,
    user_id: data?.user_id,
    customer_id: data?.customer?.id || data?.customer_id,
    cargo_item_specs: formatCargoItemsFcl(data?.cargo_item_specs),
    main_quotation_id: data?.id,
    chargesData: data?.chargesData,
    reference_no: data?.reference_no,
    ...adjustLocationWithTerms(data),
  };

  if (data?.service_type == "custom") {
    payload.chargesData = data?.chargesData;
  }

  return payload;
};

export const adjustLocationWithTerms = (data: any) => {
  if (data?.terms == "2") {
    // Port to Port
    return {
      origin_port: data?.origin_port || data?.source_port,
      destination_port: data?.destination_port,
    };
  } else if (data?.terms == "1") {
    return {
      origin_port: data?.origin_port || data?.source_port,
      origin_location: data?.pickup_location || data?.source_location,
      destination_port: data?.destination_port,
    };
    //  Door to port
  } else if (data?.terms == "3") {
    //  Door to door
    return {
      origin_port: data?.origin_port,
      origin_location: data?.pickup_location || data?.source_location,
      destination_port: data?.destination_port,
      destination_location: data?.destination_location,
    };
  } else {
    //  Port to door
    return {
      origin_port: data?.origin_port,
      destination_port: data?.destination_port,
      destination_location: data?.destination_location,
    };
  }
};

export const sumArray = (data: any) => {
  return data?.reduce((acc: any, val: any) => acc + val, 0);
};

export const getTransShipmentCount = (subQuotation: any) => {
  let count = 1;
  if (subQuotation?.isTransshipment) {
    const transhipment = JSON.parse(subQuotation.Transshipment);
    count += transhipment.length;
  }

  return count;
};

export const getTotalQuantity = (items: any) => {
  return items?.reduce((acc: any, val: any) => {
    return acc + val?.quantity;
  }, 0);
};

export const getTotalWeight = (items: any) => {
  return items?.reduce((acc: any, val: any) => {
    return acc + val?.quantity * val?.weight;
  }, 0);
};

export const addEditableFields = (input: any) => {
  // Create a new object to avoid modifying the original input
  const newInput = {
    columns: input?.columns?.map((column: any) => ({
      ...column, // Spread the existing properties of the column
      isEditable: true, // Add the isEditable property
      hide: column?.sub_total == 0,
    })),
    charges: input?.charges?.map((charge: any) => ({
      ...charge, // Spread the existing properties of the charge
      isEditable: true, // Add the isEditable property
      details: charge?.details?.map((detail: any) => ({
        ...detail, // Spread the existing properties of the detail
        isEditable: true, // Add the isEditable property to details
      })),
    })),
    one_time_charges: input.one_time_charges,
    total_price: input.total_price, // Copy total_price without modification
  };

  return newInput;
};

export const formatQuotationDate = (date: any) => {
  return moment(date).format("DD/MM/YYYY");
};

export const formatLocationForRoutes = (data: any): any => {
  if (data?.terms == "1") {
    // For Door to Port
    return {
      source: `${data?.source_location},${data?.sourcePortName}, ${data?.source_country}`,
      destination: `${data?.destinationPortName},${data?.destination_country}`,
    };
  } else if (data?.terms == "2") {
    // For Port to Port
    return {
      source: `${data?.sourcePortName}, ${data?.source_country}`,
      destination: `${data?.destinationPortName},${data?.destination_country}`,
    };
  } else if (data?.terms == "3") {
    // For Door to Door
    return {
      source: `${data?.source_location},${data?.sourcePortName}, ${data?.source_country}`,
      destination: `${data?.destination_location},${data?.destinationPortName}, ${data?.destination_country}`,
    };
  } else if (data?.terms == "4") {
    // For Port to Door
    return {
      source: `${data?.sourcePortName}, ${data?.source_country}`,
      destination: `${data?.destination_location},${data?.destinationPortName}, ${data?.destination_country}`,
    };
  }

  return { source: "", destination: "" };
};

export const findSpliceIndexForTranshipment = (terms: any) => {
  let index = 1;
  if (terms == "1") {
    index = 4;
    // For Door to Port
  } else if (terms == "2") {
    // For Port to Port
    index = 2;
  } else if (terms == "3") {
    index = 4;
    // For Door to Door
  } else if (terms == "4") {
    index = 2;
    // For Port to Door
  }
  return index;
};

export const getTotalOfSubChargeDetailForBooking = (
  detail: any,
  chargesData: any,
) => {
  let finalTotal = 0;
  if (detail.isOneTime) {
    finalTotal = detail.values[0];
  } else {
    chargesData.columns?.forEach((column: any, index: any) => {
      finalTotal += detail.values[index] * column?.quantity;
    });
  }

  return roundToTwoDecimalPoint(finalTotal);
};

export const getTotalByChargeIdForBooking = (
  chargeId: any,
  currentIndex: any,
  chargesData: any,
) => {
  //  find charge with charge id

  const charge = chargesData?.charges?.find(
    (charge: any) => charge.id == chargeId,
  );

  let finalTotal = 0;

  charge?.details.forEach((item: any) => {
    finalTotal += getTotalOfSubChargeDetailForBooking(item, chargesData);
  });

  return roundToTwoDecimalPoint(finalTotal);
};

export const getFilteredContainerTypes = (formikQuotationFcl: any) => {
  const selectedContainerTypes = formikQuotationFcl?.values?.cargo_item_specs
    ?.map((item: any) => item?.container_type)
    .filter(Boolean);

  const findContainerType = CONTAINER_TYPES.find((type: any) =>
    type.options.some((option: any) =>
      selectedContainerTypes.includes(option.value),
    ),
  );

  if (
    findContainerType &&
    formikQuotationFcl?.values?.cargo_item_specs?.length > 1
  ) {
    return CONTAINER_TYPES.filter(
      (containerType: any) => containerType.title === findContainerType?.title,
    );
  }

  return CONTAINER_TYPES;
};

export const sortCharges = (charges: any) => {
  if (charges) {
    const copyCharges = JSON.parse(JSON.stringify(charges));

    const categoryOrder = ["Freight", "Origin", "Destination"];

    copyCharges?.sort((a: any, b: any) => {
      return (
        categoryOrder?.indexOf(a?.category) -
        categoryOrder?.indexOf(b?.category)
      );
    });

    return copyCharges;
  } else {
    return [];
  }
};

export const generateUUID = () => {
  return uuidv4();
};

export const sortByQuotations = (filteredQuotations: any, sortBy: SORT_BY) => {
  const results: any[] = JSON.parse(JSON.stringify(filteredQuotations));

  console.log(results, "results.....");

  const [key, order] = sortBy.split("-"); // Extract key and order (asc/desc)
  const isAscending = order === "asc"; // Check if sorting should be ascending

  results.sort((a: any, b: any) => {
    let comparison = 0;

    if (key === "transit_time") {
      comparison = a[key] - b[key];
    } else if (key === "date") {
      const dateA = new Date(a[key]).getTime();
      const dateB = new Date(b[key]).getTime();
      comparison = dateA - dateB;
    } else if (key === "price") {
      comparison =
        parseFloat(a?.categorised_charges?.total_price) -
        parseFloat(b?.categorised_charges?.total_price);
    }

    return isAscending ? comparison : -comparison; // Reverse if descending
  });

  return results;
};

export const mergeCharges = (charges: any) => {
  if (!charges) {
    return [];
  }
  const initialCharges = JSON.parse(JSON.stringify(charges));

  const mergedCharges: Record<string, any> = {};

  // Process one_time_charges
  charges.one_time_charges.forEach((charge: any) => {
    if (!mergedCharges[charge.category]) {
      mergedCharges[charge.category] = {
        id: charge.id,
        category: charge.category,
        currency: charge.currency,
        total: charge.total,
        details: [],
      };
    } else {
      mergedCharges[charge.category].total += charge.total;
    }

    charge.details.forEach((detail: any) => {
      mergedCharges[charge.category].details.push({
        ...detail,
        values: [detail.values],
        isOneTime: true,
      });
    });
  });

  // Process regular charges
  charges.charges.forEach((charge: any) => {
    if (!mergedCharges[charge.category]) {
      mergedCharges[charge.category] = {
        id: charge.id,
        category: charge.category,
        currency: charge.currency,
        total: charge.total.reduce((sum: any, val: any) => sum + val, 0),
        details: [],
      };
    } else {
      mergedCharges[charge.category].total += charge.total.reduce(
        (sum: any, val: any) => sum + val,
        0,
      );
    }

    charge.details.forEach((detail: any) => {
      const existingDetail = mergedCharges[charge.category].details.find(
        (d: any) => d.id === detail.id,
      );

      if (existingDetail) {
        existingDetail.values = existingDetail.values.concat(detail.values);
        existingDetail.valueDetails = existingDetail.valueDetails.concat(
          detail.valueDetails,
        );
      } else {
        mergedCharges[charge.category].details.push({
          ...detail,
          values: Array.isArray(detail.values)
            ? detail.values
            : [detail.values],
        });
      }
    });
  });

  const output = Object.values(mergedCharges);

  return { ...initialCharges, charges: output };
};

export const formatContainerChargesWithTerms = (
  charges: any[],
  terms: string,
) => {
  const termsMapping: Record<string, string[]> = {
    "1": ["Origin", "Freight"], // Door to Port => pre-carriage (Origin), ocean-freight (Freight)
    "2": ["Freight"], // Port to Port => ocean-freight (Freight)
    "3": ["Origin", "Freight", "Destination"], // Door to Door => pre-carriage (Origin), ocean-freight (Freight), post-carriage (Destination)
    "4": ["Freight", "Destination"], // Port to Door => ocean-freight (Freight), post-carriage (Destination)
  };

  const selectedCategories = termsMapping[terms] || [];

  // Filter existing charges based on selected terms
  const filteredCharges = charges.filter((charge) =>
    selectedCategories.includes(charge.category),
  );

  // Ensure all required categories exist, adding missing ones with default values
  selectedCategories.forEach((category) => {
    if (!filteredCharges.some((charge) => charge.category === category)) {
      filteredCharges.push({
        id: generateUUID(), // Generate a unique ID
        category,
        currency: "EUR",
        total: [0], // Default value
        details: [
          {
            id: generateUUID(),
            name: "",
            charge_description: "",
            values: [""], // Default value
            valueDetails: [
              {
                charge_id: generateUUID(),
                value: "", // Default charge
                container_type: "",
                rate_basis: "",
              },
            ],
            isEditable: true,
          },
        ],
        isEditable: true,
      });
    }
  });

  return filteredCharges;
};

export const formatOneTimeChargesWithTerms = (
  charges: any[],
  terms: string,
) => {
  console.log(charges, "charges>>>>>");

  const chargeMapping: Record<number, string[]> = {
    1: ["Origin", "Freight"],
    2: ["Freight"],
    3: ["Origin", "Freight", "Destination"],
    4: ["Freight", "Destination"],
  };

  const selectedCategories = chargeMapping[Number(terms)] || chargeMapping[3];

  return selectedCategories.map((category) => {
    const existingCharge = charges.find(
      (charge) => charge.category === category,
    );

    return existingCharge
      ? existingCharge // Use existing charge if found
      : {
          id: generateUUID(),
          category,
          total: "",
          details: [
            {
              id: generateUUID(),
              name: "",
              values: "",
            },
          ],
        };
  });
};

export const toLocaleString = (input: number | string) => {
  input = Number(input);
  return input.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};
