import React, { useEffect, useState } from "react";
import QuotationHorizontalSteps from "src/pages/quotation-results-customer/elements/quotation-horizontal-steps";
import { findIconByValue } from "src/utils";

const QuotationRoutesBookingFcl = ({ data }: { data: any }) => {
  const [items, setItems] = useState<any[]>([]); // State for items

  const terms = data?.main_quotation?.terms;
  const destination_country = data?.main_quotation?.destination_country;
  const destination_location = data?.main_quotation?.destination_location;
  const destination_port = data?.main_quotation?.destination_port;
  const source_country = data?.main_quotation?.source_country;
  const source_port = data?.main_quotation?.source_port;
  const source_location = data?.main_quotation?.source_location;
  const date = data?.date;
  const delivery_date = data?.delivery_date;
  let transit: any = [];

  if (
    data?.Transshipment &&
    data?.Transshipment != "" &&
    data?.Transshipment !== undefined
  ) {
    transit = JSON.parse(data?.Transshipment);
    console.log(transit, "tarnsit");
  }

  useEffect(() => {
    let initialItems: any = [];

    if (terms == "1") {
      // For Door to Port
      initialItems = [
        {
          title: (
            <>
              <div className="flex flex-col whitespace-nowrap">
                <p className="text-16 font-semibold leading-24 text-gray-700 ">
                  {source_location}
                </p>
                <p className="min-w-[80px] text-12 font-normal leading-18 text-gray-600">
                  {date}
                </p>
              </div>

              <QuotationHorizontalSteps value={""} mode="road" />
            </>
          ),
        },
        {
          title: (
            <>
              <div className="flex flex-col  whitespace-nowrap">
                <p className="text-16 font-semibold leading-24 text-gray-700 ">
                  {source_port}
                </p>
              </div>

              <QuotationHorizontalSteps value={""} mode="ocean" />
            </>
          ),
        },
        {
          title: (
            <>
              <div>
                <p className="whitespace-nowrap text-16 font-semibold leading-24  text-gray-700">
                  {destination_country}
                </p>
                <p className="min-w-[80px] text-12 font-normal leading-18 text-gray-600">
                  {delivery_date}
                </p>
              </div>
            </>
          ),
        },
      ];
      // // Insert transshipment items after the 2nd index
      initialItems.splice(2, 0, {
        title: transit?.map((data: any) => {
          return (
            <>
              <div className="flex flex-col">
                <p className="text-16 font-semibold leading-24 text-gray-700 ">
                  {data?.name}
                </p>
                <p className="text-12 font-normal leading-18 text-gray-600"></p>
              </div>
              <QuotationHorizontalSteps mode={"ocean"} value={""} />
            </>
          );
        }),
      });
    } else if (terms == "2") {
      // For Port to Port
      initialItems = [
        {
          title: (
            <>
              <div className="flex flex-col whitespace-nowrap">
                <p className="text-16 font-semibold leading-24 text-gray-700 ">
                  {source_country}
                </p>
                <p className="text-12 font-normal leading-18 text-gray-600">
                  {date}
                </p>
              </div>

              <QuotationHorizontalSteps value={""} mode="ocean" />
            </>
          ),
        },
        {
          title: (
            <>
              <div>
                <p className="whitespace-nowrap text-16 font-semibold leading-24 text-gray-700">
                  {destination_country}
                </p>
                <p className="text-12 font-normal leading-18 text-gray-600">
                  {delivery_date}
                </p>
              </div>
            </>
          ),
        },
      ];
      // Insert transshipment items after the 2nd index
      initialItems.splice(1, 0, {
        title: transit?.map((data: any) => {
          return (
            <>
              <div className="flex flex-col  whitespace-nowrap">
                <p className="text-16 font-semibold leading-24 text-gray-700 ">
                  {data?.name}
                </p>
                <p className="text-12 font-normal leading-18 text-gray-600"></p>
              </div>
              <QuotationHorizontalSteps mode={"ocean"} value={""} />
            </>
          );
        }),
      });
    } else if (terms == "3") {
      // For Door to Door
      initialItems = [
        {
          title: (
            <>
              <div className="flex flex-col  whitespace-nowrap">
                <p className="text-16 font-semibold leading-24 text-gray-700 ">
                  {source_location}
                </p>
                <p className="text-12 font-normal leading-18 text-gray-600">
                  {date}
                </p>
              </div>

              <QuotationHorizontalSteps value={""} mode="road" />
            </>
          ),
        },
        {
          title: (
            <>
              <div className="flex flex-col  whitespace-nowrap">
                <p className="text-16 font-semibold leading-24 text-gray-700 ">
                  {source_port}
                </p>
              </div>

              <QuotationHorizontalSteps value={""} mode="ocean" />
            </>
          ),
        },
        {
          title: (
            <>
              <div>
                <p className="whitespace-nowrap text-16 font-semibold leading-24  text-gray-700">
                  {destination_port}
                </p>
              </div>
              <QuotationHorizontalSteps value={""} mode="road" />
            </>
          ),
        },
        {
          title: (
            <>
              <div className="whitespace-nowrap">
                <p className="text-16 font-semibold leading-24 text-gray-700">
                  {destination_location}
                </p>
                <p className="text-12 font-normal leading-18 text-gray-600">
                  {delivery_date}
                </p>
              </div>{" "}
            </>
          ),
        },
      ];
      // // Insert transshipment items after the 2nd index
      initialItems.splice(2, 0, {
        title: transit?.map((data: any) => {
          return (
            <>
              <div className="flex flex-col">
                <p className="text-16 font-semibold leading-24 text-gray-700 ">
                  {data?.name}
                </p>
                <p className="text-12 font-normal leading-18 text-gray-600"></p>
              </div>
              <QuotationHorizontalSteps mode={"ocean"} value={""} />
            </>
          );
        }),
      });
    } else if (terms == "4") {
      // For Port to Door

      initialItems = [
        {
          title: (
            <>
              <div className="flex flex-col  whitespace-nowrap">
                <p className="text-16 font-semibold leading-24 text-gray-700 ">
                  {source_country}
                </p>
              </div>

              <QuotationHorizontalSteps value={""} mode="ocean" />
            </>
          ),
        },
        {
          title: (
            <>
              <div>
                <p className="whitespace-nowrap text-16 font-semibold leading-24  text-gray-700">
                  {destination_port}
                </p>
              </div>
              <QuotationHorizontalSteps value={""} mode="road" />
            </>
          ),
        },
        {
          title: (
            <>
              <div>
                <p className="whitespace-nowrap text-16 font-semibold leading-24 text-gray-700">
                  {destination_location}
                </p>
                <p className="text-12 font-normal leading-18 text-gray-600">
                  {delivery_date}
                </p>
              </div>{" "}
            </>
          ),
        },
      ];
      // // Insert transshipment items after the 2nd index
      initialItems.splice(1, 0, {
        title: transit?.map((data: any) => {
          return (
            <>
              <div className="flex flex-col">
                <p className="text-16 font-semibold leading-24 text-gray-700 ">
                  {data?.name}
                </p>
                <p className="text-12 font-normal leading-18 text-gray-600"></p>
              </div>
              <QuotationHorizontalSteps mode={"ocean"} value={""} />
            </>
          );
        }),
      });
    }

    setItems(initialItems);
  }, [data]);

  return (
    <div className="flex flex-1 items-center justify-between rounded-[10px] bg-white px-12 py-4">
      {items?.map((item: any) => {
        return <>{item?.title}</>;
      })}
    </div>
  );
};

export default QuotationRoutesBookingFcl;
