import React, { useLayoutEffect, useRef, useState } from "react";
import { Icon } from "../icons";
import { formatToTwoDecimalPoints, toLocaleString } from "src/helpers";

const AllInRateComponent = ({ data }: { data: any }) => {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  useLayoutEffect(() => {
    // Check button visibility on initial load
    handleScroll();
  }, []);

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      const scrollLeft = scrollContainerRef.current.scrollLeft;
      const scrollWidth = scrollContainerRef.current.scrollWidth;
      const clientWidth = scrollContainerRef.current.clientWidth;

      setIsAtStart(scrollLeft === 0);
      setIsAtEnd(scrollLeft + clientWidth >= scrollWidth);
    }
  };

  const scroll = (direction: "left" | "right") => {
    if (scrollContainerRef.current) {
      const scrollAmount = 300; // Scroll amount (adjust as needed)
      if (direction === "left") {
        scrollContainerRef.current.scrollBy({
          left: -scrollAmount,
          behavior: "smooth",
        });
      } else {
        scrollContainerRef.current.scrollBy({
          left: scrollAmount,
          behavior: "smooth",
        });
      }
      handleScroll(); // Update button visibility after scrolling
    }
  };

  return (
    <>
      <button
        className={`absolute left-0 ${isAtStart ? "hidden" : ""}`}
        onClick={() => scroll("left")}
      >
        <Icon.McsIcChevronRightResult className="rotate-180" />
      </button>
      <div
        ref={scrollContainerRef}
        className="custom-scroll mx-[20px] flex h-full gap-x-2 overflow-x-auto"
        onScroll={handleScroll}
      >
        {data?.map((item: any) => (
          <div
            key={item.id}
            className="shadow flex h-full min-w-[140px] flex-shrink-0 flex-col items-center justify-center rounded-lg border border-border p-12"
          >
            <span className="text-14 text-sm font-medium leading-18 text-gray-500">
              {item.container_type == "PER SHIPMENT"
                ? `Per Shipment`
                : `Per ${item.container_type}`}
            </span>
            <hr className="my-8 w-full border-border"></hr>
            <span className="flex items-center justify-between gap-x-2">
              <span className="text-12 font-normal leading-24 text-gray-500-base">
                EUR
              </span>
              <span className="text-24 font-semibold leading-32 text-gray-800">
                {toLocaleString(formatToTwoDecimalPoints(item?.unit_price))}
              </span>
            </span>
          </div>
        ))}
      </div>
      <button
        className={`absolute right-0 ${isAtEnd ? "hidden" : ""}`}
        onClick={() => scroll("right")}
      >
        <Icon.McsIcChevronRightResult />
      </button>
    </>
  );
};

export default AllInRateComponent;
